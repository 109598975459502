import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { HeroGlossary } from "../components/hero"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import { splitTitle } from "../util/glossary";

function GlossaryPage({ data: { nodeGlossaryLobby, allNodeGlossaryItem } }) {

  const { title, title2 } = nodeGlossaryLobby
  const seo = getMetatags(nodeGlossaryLobby.metatag, nodeGlossaryLobby.relationships.OGImage?.localFile?.publicURL)

  const terms = allNodeGlossaryItem.nodes

  const [keywords, setKeywords] = useState('')
  const [category/* , setCategory */] = useState('')

  const items = filter(terms, keywords, category)

  //console.log(items)
  return (
    <Layout showCta={false} >
      <Seo {...seo} />
      <HeroGlossary title={title2} tag={title} >
        <GlossarySearch value={keywords} onChange={setKeywords} />
      </HeroGlossary>
      {/* <Categories items={categories} onChange={setCategory} /> */}
      <Teasers items={items} />
    </Layout>
  )
}

const filter = (items, keywords, category = '') => {

  const teasers = items.map((item) => {
    let search = '' // item.title.toLowerCase() + ' ' + item.teaser.toLowerCase()
    if (item.title) {
      search += item.title.toLowerCase()
    }
    if (item.teaser) {
      search += item.teaser.toLowerCase()
    }
    var path = item.path.alias
    item.path.alias = (path.charAt(path.length - 1) !== '/') ? item.path.alias + '/' : item.path.alias
    return { ...item, search: search }
  })
  const filtered = teasers
    .filter(e => e.search.includes(keywords.toLowerCase()))
    .filter(e => category === '' || e.relationships.category.id === category)

  return filtered

}

const Teasers = ({ items }) => {

  const getTitle = (title) => {
    const titles =  splitTitle(title)
    return titles.breadcrumb
  }

  
  return (
    <section className="py-32">
      <div className="container max-w-[1200px]">

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-12 gap-y-4 lg:gap-y-16">
          {items.map((item, index) => (
            <Link  to={item.path.alias} key={index}>
            <div className="h-fit border hover:border-black rounded-[10px]  shadow-xl	 p-10 relative flex flex-col xl:h-[250px] lg:h-[265px]" key={index}>
              <div className="top-0 left-0 absolute rounded-tl-[10px]  overflow-hidden">
                <Corner color={item.color.color} />
              </div>
              <div className="">
                <h3 className="text-[28px] lg:text-[24px] leading-[30px] lg:leading-[26px] font-bold	pb-4 lg:pb-2 lg:h-[60px] ">
                  <Link className="hover:text-blue" to={item.path.alias} dangerouslySetInnerHTML={{__html: getTitle(item.title)}} />
                  </h3>
                <div className="min-h-[132px] font-base text-gray-700 ">
                  <Link className="hover:text-blue" to={item.path.alias}>{item.teaser}</Link>
                  </div>
              </div>
            </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  )
}

const Corner = ({ color }) => {
  return (
    <svg width="41" height="35" viewBox="0 0 41 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M41 0L0 35V0H41Z" fill={color} />
    </svg>
  )
}

const GlossarySearch = ({ value, onChange }) => {

  return (
    <div className="max-w-[500px] mx-auto pt-12">
      <input
        type="search"
        value={value}
        className="w-full h-12 pl-12 pr-4 rounded-full border border-gray-500 bg-white bg-no-repeat bg-left bg-[center_left_8px]"
        placeholder="Search Glossary..."
        style={{ backgroundImage: `url('/magnifying-glass.svg')` }}
        onChange={(e) => { onChange(e.target.value) }}
      />
    </div>
  )
}

export default GlossaryPage

/* export const Head = () => <title>Overview Page</title> */


export const query = graphql`
  query glossary($id: String!, $status: [Boolean!]!) {
    nodeGlossaryLobby(id: { eq: $id }) {
      id
      title
      path {
        alias
      }
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      title2: field_title
      
      relationships {
        OGImage: field_image {
          ...Image
        }
      }
    }
    allNodeGlossaryItem(filter: {status: {in: $status }}) {
      nodes {
        id
        title
        path {
          alias
        }
        teaser: field_description2
        color: field_color {
          color
        }
      }
    }
  }
`

/*
        relationships {
          category : field_category {
            name
            id
          }
        }

*/
